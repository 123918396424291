import { environment } from './../../../environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  constructor(private client: HttpClient) { }


  get(path: string, params = new HttpParams()) {
    return this.client.get(`${environment.api}/${path}`, { params });
  }

  put(path: string, body: Object = {}, params: HttpParams = new HttpParams()) {
    return this.client.put(`${environment.api}/${path}`, body, {
      params
    });
  }

  post(path: string, body: Object = {}, params: HttpParams = new HttpParams()) {
    return this.client.post(`${environment.api}/${path}`, body, {
      params
    });
  }

  patch(path: string, body: Object = {}, params: HttpParams = new HttpParams()) {
    return this.client.patch(`${environment.api}/${path}`, body, {
      params
    });
  }

  delete(path: string) {
    return this.client.delete(`${environment.api}/${path}`);
  }






}
